<template>
  <div class="home">
    <div class="container">
      <v-header :menu="true" />
    </div>
    <div class="section dt12 db15 mt7 mb10">
      <div class="container">
        <div class="row">
          <div class="d-10 d-center">
            <h1>
              Снижайте нагрузку на&nbsp;отдел персонала с&nbsp;помощью
              автоматизации КЭДО
            </h1>
          </div>
          <div class="d-8 d-center dt7 mt6">
            <p class="leading db7 mb6">
              Цифровое делопроизводство, передача рутинных задач умным роботам
              и&nbsp;сквозная интеграция всех систем
            </p>
            <a href="#" class="button m-center" @click.prevent="showModal">
              Отправить запрос
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="section db15 mb10">
      <div class="container">
        <div class="row middle">
          <div class="d-6 d-offset-1 d-hide mb4">
            <img src="@/assets/pages/kedo-automation/pic-01.svg"
              data-copyright-source="https://storyset.com/illustration/work-time/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
          <div class="d-6" v-onscroll>
            <h2>Зачем нужна автоматизация</h2>
            <p class="md">
              Чем больше объём кадровых документов, тем выше нагрузка
              на&nbsp;отдел персонала и&nbsp;больше расходы. WorkCat освобождает
              сотрудников от&nbsp;рутины и&nbsp;помогает хранить данные
              в&nbsp;одном месте, избавляя от&nbsp;затрат на&nbsp;интеграции.
            </p>
            <p class="md">
              Сервис объединяет потоки кадровых документов из&nbsp;HRM, ERP,
              CRM, СЭД. Вы&nbsp;сможете создавать маршруты подписания
              и&nbsp;согласования документов, а&nbsp;распознавание и&nbsp;ввод
              данных возьмут на&nbsp;себя <a href="https://ediweb.com/ru-ru/automation/bussiness/linkserver-rpa" target="_blank"
                >роботы</a>. Они перенесут документ
              в&nbsp;архив или запустят процесс согласования.
            </p>
            <p class="md">
              Роботы выполняют работу в&nbsp;автономном режиме, никогда
              не&nbsp;спят и&nbsp;не&nbsp;ошибаются. Если количество документов
              вырастет, вам не&nbsp;придётся нанимать дополнительный персонал.
            </p>
          </div>
          <div class="d-5 d-offset-1 m-hide" v-onscroll>
            <img src="@/assets/pages/kedo-automation/pic-01.svg"
              data-copyright-source="https://storyset.com/illustration/work-time/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="section section-dark v15 mt7 mb7" v-onscroll>
      <div class="container">
        <div class="row">
          <div class="d-12 dt10 db7 mt7 d-center">
            <h2>Этапы работ</h2>
          </div>
        </div>
        <div class="row">
          <div class="d-3 db11 mt6" v-onscroll>
            <div class="card">
              <p class="card-title">1</p>
              <p class="card-subtitle dt1 db3 mt1 mb2">Бизнес-анализ</p>
              <p class="card-text">
                Проанализируем ваши процессы. Найдём слабые и&nbsp;сильные
                места. Предложим лучшее решение.
              </p>
            </div>
          </div>
          <div class="d-3 db11 mt6" v-onscroll="{ delay: 250 }">
            <div class="card">
              <p class="card-title">2</p>
              <p class="card-subtitle dt1 db3 mt1 mb2">Внедрение</p>
              <p class="card-text">
                Бесшовно соединим решение с&nbsp;вашими системами. Настроим
                алгоритмы, роботов и&nbsp;процессы.
              </p>
            </div>
          </div>
          <div class="d-3 db11 mt6" v-onscroll="{ delay: 500 }">
            <div class="card">
              <p class="card-title">3</p>
              <p class="card-subtitle dt1 db3 mt1 mb2">Тестирование</p>
              <p class="card-text">
                Проверим процессы на&nbsp;небольшой группе сотрудников. Соберём
                обратную связь и&nbsp;внесём коррективы.
              </p>
            </div>
          </div>
          <div class="d-3 db11 mt6 mb7" v-onscroll="{ delay: 750 }">
            <div class="card">
              <p class="card-title">4</p>
              <p class="card-subtitle dt1 db3 mt1 mb2">Запуск</p>
              <p class="card-text">
                Масштабируем решение на&nbsp;всю компанию. Обучим сотрудников
                и&nbsp;обеспечим техподдержку.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="section mv10" v-onscroll>
      <div class="container">
        <div class="row dt13 db15">
          <div class="d-12">
            <div class="card card-lg">
              <div class="row middle v6 mv1">
                <div class="d-5 dr10 mb4">
                  <img src="@/assets/pages/kedo-automation/thumbnail.png" />
                </div>
                <div class="d-6">
                  <h2 class="db4 mb4">
                    Пример автоматизации согласования документов
                  </h2>
                  <p class="m-hide">
                    Скачайте схему развёртывания WorkCat и&nbsp;посмотрите,как
                    вы&nbsp;можете организовать свою работу.
                  </p>
                  <div class="dt4 mt3">
                    <a
                      href="#"
                      download
                      class="button button-sm m-center dr4 mb4"
                    >
                      Скачать
                    </a>
                    <a
                      href="#"
                      target="_blank"
                      class="button button-inverse button-sm m-center"
                    >
                      Посмотреть
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="section db15 mb10">
      <div class="container">
        <div class="row middle">
          <div class="d-6 d-offset-1 d-hide mb4">
            <img src="@/assets/pages/kedo-automation/pic-02.svg"
              data-copyright-source="https://storyset.com/illustration/documents/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
          <div class="d-5" v-onscroll>
            <h2>Автоматизация рутины</h2>
            <p>
              <strong>Избавляйтесь от&nbsp;рутины.</strong> Вам
              не&nbsp;потребуется переносить информацию из&nbsp;бумажных
              документов в&nbsp;электронный вид. Распознавание и&nbsp;ввод
              данных возьмут на&nbsp;себя роботы.
            </p>
            <p>
              <strong>Отправляйте документы сразу всем.</strong> Всего
              в&nbsp;несколько кликов можно сформировать и&nbsp;отправить
              сотрудникам уведомления о&nbsp;необходимости подписать приказ,
              распоряжение или ведомость с&nbsp;помощью&nbsp;ЭП.
            </p>
            <p>
              <strong>Используйте электронный архив.</strong> Все документы
              хранятся в&nbsp;электронном архиве, который можно разместить
              на&nbsp;вашем сервере или в&nbsp;облаке. Найти нужный документ можно
              в&nbsp;пару кликов с&nbsp;помощью удобных фильтров.
            </p>
          </div>
          <div class="d-6 d-offset-1 m-hide" v-onscroll>
            <img src="@/assets/pages/kedo-automation/pic-02.svg"
              data-copyright-source="https://storyset.com/illustration/documents/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
        </div>

        <div class="row middle dv15 mv10">
          <div class="d-5 mb4" v-onscroll>
            <img src="@/assets/pages/kedo-automation/pic-03.svg"
              data-copyright-source="https://storyset.com/illustration/setup-analytics/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
          <div class="d-6 d-offset-1" v-onscroll>
            <h2>Интеграция с&nbsp;любыми системами</h2>
            <p>
              <strong>Работайте в&nbsp;привычном интерфейсе.</strong> Сотрудники
              могут работать в&nbsp;корпоративных системах, переходить
              в&nbsp;новый интерфейс не&nbsp;потребуется. Мы&nbsp;настроим
              синхронизацию данных и&nbsp;роботизированные процессы
              в&nbsp;фоновом режиме.
            </p>
            <p>
              <strong>Передавайте данные в&nbsp;другие сервисы.</strong> WorkCat
              позволяет передавать данные другим провайдерам (Ediweb, Контур.
              Диадок) и&nbsp;на&nbsp;государственные порталы (Работа
              в&nbsp;России, Госуслуги).
            </p>
            <p>
              <strong>Бесшовно соединяйте системы.</strong> Мы&nbsp;свяжем ваши
              СЭД и&nbsp;HRD в&nbsp;едином центре управления потоками
              документов. Вам не&nbsp;придётся больше вручную переносить данные
              из&nbsp;одной системы в&nbsp;другую.
            </p>
          </div>
        </div>

        <div class="row middle">
          <div class="d-6 d-offset-1 d-hide mb4">
            <img src="@/assets/pages/kedo-automation/pic-04.svg"
              data-copyright-source="https://storyset.com/illustration/completed-steps/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
          <div class="d-5" v-onscroll>
            <h2>Конструкторы процессов</h2>
            <p>
              <strong>Настраивайте логику согласования.</strong> Формируйте
              маршруты согласования документов между сотрудниками
              и&nbsp;передавайте документы по&nbsp;заданной логике. Легко
              отслеживайте статусы подписания и&nbsp;состояния документов.
            </p>
            <p>
              <strong>Назначайте роли сотрудникам.</strong> Если нужно разделить
              доступы к&nbsp;разным документам и&nbsp;права работы с&nbsp;ними,
              это легко сделать в&nbsp;интерфейсе WorkCat.
            </p>
            <p>
              <strong>Используйте готовые шаблоны.</strong> Создавайте документы
              на&nbsp;основе акцептованных данных от&nbsp;сотрудника
              и&nbsp;работодателя. Сотрудник может запросить справку
              с&nbsp;места работы, HR-специалист подтвердит, а&nbsp;система
              на&nbsp;основе шаблона выпустит справку с&nbsp;ЭП от&nbsp;юрлица.
            </p>
          </div>
          <div class="d-6 d-offset-1 m-hide" v-onscroll>
            <img src="@/assets/pages/kedo-automation/pic-04.svg"
              data-copyright-source="https://storyset.com/illustration/completed-steps/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container db13 mb10">
        <div class="row">
          <div class="d-12 db7 mb6" v-onscroll>
            <h2>Почему WorkCat</h2>
          </div>
          <div class="d-5" v-onscroll>
            <div class="sign sign-vertical db7 mb6">
              <img class="sign-image" src="@/assets/icons/rocket.svg" />
              <h4 class="dt4 mt3 b2">Готовые решения</h4>
              <div class="sign-descr">
                Вам ничего не&nbsp;нужно разрабатывать с&nbsp;нуля.
                Мы&nbsp;соберём для вас персональное решение для КЭДО
                на&nbsp;базе технологий и&nbsp;продуктов Ediweb.
              </div>
            </div>
          </div>

          <div class="d-6 d-offset-1" v-onscroll>
            <div class="sign sign-vertical db7 mb6">
              <img class="sign-image" src="@/assets/icons/sheld.svg" />
              <h4 class="dt4 mt3 b2">Защита от&nbsp;рисков</h4>
              <div class="sign-descr">
                Используем защищённые каналы связи и&nbsp;дата-центры
                с&nbsp;резервными мощностями. Документы храним в&nbsp;надёжном
                электронном архиве с&nbsp;фильтрами для быстрой выгрузки нужных
                файлов.
              </div>
            </div>
          </div>

          <div class="d-5" v-onscroll="{ delay: 250 }">
            <div class="sign sign-vertical db7 mb6">
              <img class="sign-image" src="@/assets/icons/trust.svg" />
              <h4 class="dt4 mt3 b2">Опыт в&nbsp;ЭДО</h4>
              <div class="sign-descr">
                Разработчик WorkCat&nbsp;&mdash; доверенный оператор ФНС ЭДО
                <a href="https://ediweb.com/" target="_blank"
                >Ediweb</a>. Работем больше 20&nbsp;лет на&nbsp;рынке электронного
                документооборота.
              </div>
            </div>
          </div>

          <div class="d-6 d-offset-1" v-onscroll="{ delay: 250 }">
            <div class="sign sign-vertical db7 mb6">
              <img class="sign-image" src="@/assets/icons/customize.svg" />
              <h4 class="dt4 mt3 b2">Кастомизация</h4>
              <div class="sign-descr">
                Доработаем функциональность продукта под внутренние процессы,
                адаптируем&nbsp;UI под ваш корпоративный стиль.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-light">
      <div class="container" v-onscroll>
        <div class="row db7 mb6">
          <div class="d-offset-1 d-9 dt12 mt7 d-center">
            <h2>Получите персональную консультацию</h2>
            <p class="lg">
              Оставьте заявку, и&nbsp;мы&nbsp;расскажем подробнее
              о&nbsp;функционале, вариантах внедрения и&nbsp;кастомизации,
              проведём презентацию решения.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="d-offset-3 d-6 db12 mb7">
            <v-contact-form action="https://ediweb.com/subscribe/9040b663" :centered="true" :openForm="true" />
          </div>
        </div>
      </div>
    </div>

    <v-modal-wrapper name="contacts">
      <v-contact-form
        action="https://ediweb.com/subscribe/9040b663"
        title="Отправить запрос"
        description="Мы свяжемся с вами, проведём предварительную оценку
        и отправим вам индвидуальное предложение."
      >
      </v-contact-form>
    </v-modal-wrapper>
  </div>
</template>

<script>
const meta = {
  title: "WorkCat − автоматизация кадрового документооборота и HR-процессов",
  description: "Управление подписанием и маршрутизацией кадровых документов. Помощь в запуске проектов по КЭДО",
};

export default {
  name: "KedoAutomation",
  components: {
    "v-header": () => import("@/components/v-header.vue"),
    "v-modal-wrapper": () => import("@/components/v-modal-wrapper.vue"),
    "v-contact-form": () => import("@/components/v-contact-form.vue"),
  },
  metaInfo: {
    title: meta.title,
    meta: [
      {
        name: "og:title",
        content: meta.title,
      },
      {
        name: "description",
        content: meta.description,
      },
      {
        name: "og:description",
        content: meta.description,
      },
    ],
  },
  methods: {
    showModal() {
      this.$modal.show("contacts");
    },
  },
};
</script>
